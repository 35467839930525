import {TokenDetail} from "./analyticsApi";
import {Box, Button, IconButton, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React from "react";
import FormatRise from "../../Component/FormatRise";
import NumToKM from "../../Component/NumToKM";
import TimeAgo from "javascript-time-ago";

const copyToClipboard = (text: string): void =>
{
    navigator.clipboard.writeText(text).then(() => {});
}

export default function TokenInfoView ({data}: {data: TokenDetail}) {
    const timeAgo = new TimeAgo('en-US');
    const startsAt = new Date(data.createdAt);
    const oldness = (new Date).getTime() - startsAt.getTime();
    return (
        <Box>
            <Box>
                <Typography sx={{fontFamily: 'Monospace'}} component="span">
                    {data.address.substring(0, 10) + "..." + data.address.substring(data.address.length - 10)}
                </Typography>
                <IconButton onClick={() => copyToClipboard(data.address)}><ContentCopyIcon sx={{fontSize: 16}} /></IconButton>
            </Box>
            <Box sx={{display: "flex", mt:1 }}>
                <Box sx={{flexGrow: 1, maxWidth: "40%", overflowX: "hidden"}}>
                    <Typography component="div" sx={{fontSize: 24, fontWeight: 700}}>{data.symbol}</Typography>
                    <Typography component="div" sx={{fontSize: 12}} color={oldness > 1800000 ? "warning": "success"}>{timeAgo.format(startsAt)}</Typography>
                </Box>
                <Box sx={{ml: 2, display: "flex"}}>
                    <Box sx={{ml: 2}}>
                        <Typography sx={{textAlign: "center", fontSize: 14}}>MCap</Typography>
                        <NumToKM num={data.lastMarketCap} />
                        <FormatRise num={data.lastRise} />
                    </Box>
                    <Box sx={{ml: 4}}>
                        <Typography sx={{textAlign: "center", fontSize: 14}}>Max</Typography>
                        <NumToKM num={data.maxMarketCap} />
                        <FormatRise num={data.maxRise} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{mt:2, ml: -1 }}>
                <Button href={"https://dexscreener.com/solana/" + data.address} target="_blank" size="medium" variant="outlined" sx={{textTransform: "none"}}>DexScreener</Button>
                <Button href={"https://solscan.io/account/" + data.devAddress} target="_blank" size="medium" variant="outlined" sx={{textTransform: "none", ml: 2}}>Dev (Solscan)</Button>
            </Box>
        </Box>
    );
}
import {
    Box,
    Card, CardContent,
    Container,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Preloader from "../../Component/Preloader";
import {GetTopChargerListResponse} from "./analyticsApi";
import fetchApiRequest from "../../DataProvider/RestApi";
import ChargerInfoView from "./ChargerInfoView";

let inProgress: boolean = false;
export default function TopChargerListView() {

    const [data, setData] = useState<GetTopChargerListResponse|undefined|null>(undefined);

    useEffect(() => {
        if (data !== undefined || inProgress) {
            return;
        }
        inProgress = true;
        fetchApiRequest<GetTopChargerListResponse>('/analytics/get-top-chargers', {}).then(response => {
            if (response.data) {
                setData(response.data);
            } else {
                setData(null);
            }
        }).catch(() => setData(null)).finally(() => {inProgress = false});
    }, [data]);

    if (!data) {
        return (
            <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
                <Preloader />
            </Container>
        );
    }

    return (
        <Box sx={{pb: 8, pt: 2}}>
            <Typography variant="h5">Top chargers</Typography>
            {
                data.list.map((item) => {
                    return (
                        <Card sx={{display: "block", borderRadius: 2, my: 1}} key={item.id}>
                            <CardContent>
                                <ChargerInfoView data={item} />
                            </CardContent>
                        </Card>
                    );
                })
            }
        </Box>
    );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import TelegramApp from './TelegramApp';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {getTheme} from "./theme";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addDefaultLocale(en);

window.addEventListener("load", () => {
    window.Telegram.WebApp.disableVerticalSwipes();
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={getTheme(true)}>
            <CssBaseline />
            <TelegramApp />
        </ThemeProvider>
    </React.StrictMode>
);

import React, {useEffect, useState} from 'react';
import {Alert, Button, Container} from "@mui/material";
import Layout from "./Layout";
import {BrowserRouter} from "react-router";
import {ProfileResponse} from "./Feature/Profile/profileApi";
import fetchApiRequest from "./DataProvider/RestApi";
import Preloader from './Component/Preloader';

export function FatalErrorScreen(params: {text: string, onTryAgain?: {(): void}}) {
    return (
        <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center", flexDirection: "column" }}>
            <Alert severity="error">{ params.text }</Alert>
            <Button sx={{mt: 2}} onClick={params.onTryAgain} hidden={params.onTryAgain === undefined}>Try again</Button>
        </Container>
    );
}

let inProgress: boolean = false;
export default function TelegramApp() {
    const [profile, setProfile] = useState<ProfileResponse|undefined|null>(undefined);

    useEffect(() => {
        if (profile !== undefined || inProgress) {
            return;
        }
        inProgress = true;
        fetchApiRequest<ProfileResponse>('/profile/me', {}).then(response => {
            if (response.data) {
                setProfile(response.data);
            } else {
                setProfile(null);
            }
        }).catch(() => {
            setProfile(null)
        }).finally(() => {inProgress = false});

    }, [profile]);

    if (profile === undefined) {
        return (
            <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
                <Preloader />
            </Container>
        );
    }

    if (profile === null) {
        return (<FatalErrorScreen text={"Network error"} onTryAgain={() => setProfile(undefined)} />);
    }

    if (profile.status !== "active" && profile.status !== "privileged") {
        return (<FatalErrorScreen text={"Private area"} />);
    }

    return (
        <BrowserRouter>
            <Layout />
        </BrowserRouter>
    );
}

import {ChargerDetail} from "./analyticsApi";
import {Box, Typography} from "@mui/material";
import FormatRise from "../../Component/FormatRise";
import React from "react";

export default function ChargerStatsView({data}: {data: ChargerDetail}) {
    const successPercentage = Math.round((data.successTokenReleases / data.tokenReleases) * 100);
    const successPercentageColor = (successPercentage > 50) ? "success" : "error";
    return (
        <Box sx={{display: "flex", gap: 1, justifyContent: "space-between", mt: 2}}>
            <Box sx={{textAlign: "center"}}>
                <Typography>Cases</Typography>
                <Typography>
                    {data.successTokenReleases}/{data.tokenReleases}
                    <Typography component="span" color={successPercentageColor} sx={{fontWeight: 700}}> ({successPercentage}%)</Typography>
                </Typography>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <Typography>Median</Typography>
                <FormatRise num={data.medianTokenRise} />
            </Box>
            <Box sx={{textAlign: "center"}}>
                <Typography>Best</Typography>
                <FormatRise num={data.maxTokenRise} />
            </Box>
            <Box sx={{textAlign: "center"}}>
                <Typography>Worst</Typography>
                <FormatRise num={data.minTokenRise} />
            </Box>
        </Box>

    );
}
import {Charger} from "./analyticsApi";
import {Box, Button, IconButton, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React from "react";
import {Link} from "react-router";
import ChargerStatsView from "./ChargerStatsView";

const copyToClipboard = (text: string): void =>
{
    navigator.clipboard.writeText(text).then(() => {});
}

export default function ChargerInfoView ({data}: {data: Charger}) {
    return (
        <Box>
            <Box>
                <Typography sx={{fontFamily: 'Monospace'}} component="span">
                    {data.address.substring(0, 10) + "..." + data.address.substring(data.address.length - 10)}
                </Typography>
                <IconButton onClick={() => copyToClipboard(data.address)}><ContentCopyIcon sx={{fontSize: 16}} /></IconButton>
            </Box>
            <Typography>
                {data.name && (<Typography component="span" color="warning" sx={{fontWeight: "bold"}}>{data.name}</Typography>)}
            </Typography>
            <ChargerStatsView data={data} />
            <Box sx={{mt: 2, ml: -1 }}>
                <Button component={Link} to={"/chargers/detail/" + data.id} size="medium" variant="contained" sx={{textTransform: "none"}}>View details</Button>
                <Button href={"https://solscan.io/account/" + data.address} target="_blank" size="medium" variant="outlined" sx={{textTransform: "none", ml: 2}}>Charger (Solscan)</Button>
            </Box>
        </Box>
    );
}
import {GetChargerTokenListResponse} from "./analyticsApi";
import {Box, Card, CardContent, Container, Typography} from "@mui/material";
import Preloader from "../../Component/Preloader";
import React, {useEffect, useState} from "react";
import fetchApiRequest from "../../DataProvider/RestApi";
import TokenInfoView from "./TokenInfoView";
let inProgress: boolean = false;

export default function ChargerTokenListView({id}: {id: string}) {
    const [data, setData] = useState<GetChargerTokenListResponse|undefined|null>(undefined);

    useEffect(() => {
        if (data !== undefined || inProgress) {
            return;
        }
        inProgress = true;
        fetchApiRequest<GetChargerTokenListResponse>(`/analytics/charger/${id}/tokens`, {}).then(response => {
            if (response.data) {
                setData(response.data);
            } else {
                setData(null);
            }
        }).catch(() => setData(null)).finally(() => {inProgress = false});
    }, []);

    if (!data) {
        return (
            <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
                <Preloader />
            </Container>
        );
    }
    return (
        <Box sx={{pb: 8, pt: 2}}>
            <Typography variant="h5">Charger tokens</Typography>
            {
                data.list.map((item) => {
                    return (
                        <Card sx={{display: "block", borderRadius: 2, my: 1}} key={item.address}>
                            <CardContent>
                                <TokenInfoView data={item} />
                            </CardContent>
                        </Card>
                    );
                })
            }
        </Box>
    );
}
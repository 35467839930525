import {Mutex} from "async-mutex";
import AuthTokenStorage from "./AuthTokenStorage";

const baseApiUrl = window.runtime.REACT_APP_BASE_API_URL;

const refreshTokenEndpoint = "/auth/refresh-token";
const authTgUserEndpoint = "/auth/telegram-mini-app";

type QueryParams = string[][] | Record<string, string> | string | URLSearchParams;

interface ApiRequestInit extends RequestInit {
    params?: QueryParams,
    payload?: any,
}

interface ApiResponse<T> extends Response {
    data?: T,
    error?: any,
}

const refreshTokenMutex = new Mutex();
const authMutex = new Mutex();

export default async function fetchApiRequest<R>(endpoint: string, init: ApiRequestInit): Promise<ApiResponse<R>> {
    const query = init.params ? "?" + (new URLSearchParams(init.params)).toString() : "";

    let token = AuthTokenStorage.get();
    if (!token && window.Telegram.WebApp.initData) {
        if (!authMutex.isLocked()) {
            const release = await authMutex.acquire();
            try {
                await authTgUser(window.Telegram.WebApp.initData);
            } finally {
                release();
            }
        } else {
            await authMutex.waitForUnlock();
        }
        token = AuthTokenStorage.get();
    }

    if (!token) {
        throw new Error("You are not authorized");
    }

    const request = {
        ...init,
        headers: {
            ...init.headers,
            "Accept": "application/json",
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
    };

    if (init.payload) {
        request.body = JSON.stringify(init.payload);
    }

    return fetch(baseApiUrl + endpoint + query, request).then(async (response) => {
        const data = await response.json();
        if (response.status === 401 && data.message === "Token expired. Refresh it") {
            if (!refreshTokenMutex.isLocked()) {
                const release = await refreshTokenMutex.acquire();
                try {
                    await refreshToken();
                    response = await fetchApiRequest<R>(endpoint, init);
                } finally {
                    release();
                }
            } else {
                await refreshTokenMutex.waitForUnlock();
                response = await fetchApiRequest<R>(endpoint, init)
            }
        }
        if (!response.ok) {
            if (response.status === 401) {
                AuthTokenStorage.del();
            }
            return {
                ...response,
                error: data,
            };
        }
        return {
            ...response,
            data: data as R,
        }
    }).catch((reason): any => {
        return reason;
    }).finally(() => {

    });
}

async function refreshToken() {
    try {
        const refreshResponse = await fetch(baseApiUrl + refreshTokenEndpoint, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({token: AuthTokenStorage.get()})
        });
        if (refreshResponse.ok) {
            const data = await refreshResponse.json();
            if (data.token) {
                AuthTokenStorage.set(data.token);
                console.info("Auth token refreshed");
                return;
            }
        }
    } catch {
        AuthTokenStorage.del();
        throw new Error("Fatal error");
    }
    AuthTokenStorage.del();
}

async function authTgUser(initData: string) {
    try {
        const refreshResponse = await fetch(baseApiUrl + authTgUserEndpoint, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({data: initData})
        });
        if (refreshResponse.ok) {
            const data = await refreshResponse.json();
            if (data.token) {
                AuthTokenStorage.set(data.token as string);
                console.info("Telegram user auth done");
                return;
            }
        }
    } catch (e) {
        AuthTokenStorage.del();
        throw new Error("Fatal error");
    }
    AuthTokenStorage.del();
}
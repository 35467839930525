import {Link, Route, Routes, useLocation} from "react-router";
import React from "react";
import {ChargingStation, Leaderboard} from "@mui/icons-material";
import {BottomNavigation, BottomNavigationAction, Container, Paper} from "@mui/material";
import MarketScreen from "./MarketScreen";
import ChargersScreen from "./ChargersScreen";
import TopChargerListView from "../Feature/Analytics/TopChargerListView";
import ChargerDetailView from "../Feature/Analytics/ChargerDetailView";

export default function Layout() {

    const getMainPage = (pathname: string): string => {
        const rex = /^(\/[a-zA-Z0-9\-_]*)/;
        const result = rex.exec(pathname);
        if (result && result.length > 1) {
            return result[1];
        } else {
            return "";
        }
    }

    return (
        <Container>
                <Routes>
                    <Route path="/" element={<MarketScreen />} />
                    <Route path="/chargers" element={<ChargersScreen />}>
                        <Route index element={<TopChargerListView />} />
                        <Route path="detail/:address" element={<ChargerDetailView />} />
                    </Route>
                </Routes>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels value={getMainPage(useLocation().pathname)}>
                    <BottomNavigationAction component={Link} to="/" value="/" label="Tokens" icon={<Leaderboard />} />
                    <BottomNavigationAction component={Link} to="/chargers" value="/chargers" label="Chargers" icon={<ChargingStation />} />
                </BottomNavigation>
            </Paper>
        </Container>
    );
}
import {useParams} from "react-router";
import {Box, Button, Container, Divider, IconButton, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Charger} from "./analyticsApi";
import fetchApiRequest from "../../DataProvider/RestApi";
import Preloader from "../../Component/Preloader";
import ChargerStatsView from "./ChargerStatsView";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChargerTokenListView from "./ChargerTokenListView";
let inProgress: boolean = false;

const copyToClipboard = (text: string): void =>
{
    navigator.clipboard.writeText(text).then(() => {});
}

export default function ChargerDetailView() {
    const { address }= useParams();

    const [data, setData] = useState<Charger|undefined|null>(undefined);

    useEffect(() => {
        if (data !== undefined || inProgress) {
            return;
        }
        inProgress = true;
        fetchApiRequest<Charger>('/analytics/charger/' + address, {}).then(response => {
            if (response.data) {
                setData(response.data);
            } else {
                setData(null);
            }
        }).catch(() => setData(null)).finally(() => {inProgress = false});
    }, [data]);


    if (!address) {
        return (
            <h4>Error</h4>
        );
    }

    if (!data) {
        return (
            <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
                <Preloader />
            </Container>
        );
    }
    const publicName =
        data.name !== null
            ? <Typography component="span" color="warning">{data.name}</Typography>
            : <Typography component="span" color="grey">unknown</Typography>;
    return (
        <Box sx={{pb: 8, pt: 2}}>
            <Box sx={{display: "flex"}}>
                <Typography variant="h5">Charger details</Typography>
                <IconButton onClick={() => copyToClipboard(data.address)}><ContentCopyIcon sx={{fontSize: 16}} /></IconButton>
            </Box>
            <Typography sx={{fontFamily: 'Monospace', fontSize: "0.9em"}}>
                { data.address }
            </Typography>
            <Divider />
            <Typography>Public name: {publicName}</Typography>
            <ChargerStatsView data={data} />
            <Divider />
            <Button href={"https://solscan.io/account/" + data.address} target="_blank" variant="outlined" size="small">View on SolScan</Button>

            <ChargerTokenListView id={data.id}/>

        </Box>
    );


}
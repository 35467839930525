const LOCAL_STORAGE_KEY_SUFFIX = 'auth-';
const telegramId = window.Telegram.WebApp.initDataUnsafe.user?.id;

const AuthTokenStorage = {
    get: (): string|undefined => {
        if (!telegramId) {
            return undefined;
        }
        return window.localStorage.getItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`) ?? undefined;
    },

    set: (token: string) => {
        window.localStorage.setItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`, token);
    },

    del: () => {
        if (telegramId === undefined) {
            return;
        }
        window.localStorage.removeItem(`${LOCAL_STORAGE_KEY_SUFFIX}${telegramId}`);
    }

}

 export default AuthTokenStorage;